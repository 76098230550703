<template>
  <div>
    <b-row
      class="mt-4"
      v-for="(schema, index_row) in schemas"
      :key="`bank_account_add_edit_row${index_row}`"
    >
      <CreateForm
        v-for="(item, index_item) in schema"
        :key="`input_bank${index_item}`"
        :schema="item"
        v-model="model[item.model]"
        :errors="errors"
      />
    </b-row>
    <div class="d-flex justify-content-end my-4">
      <b-button v-if="editing" variant="primary-custom" @click="update_account"
        >Salvar Alterações</b-button
      >
      <b-button v-else variant="primary-custom" @click="submit_new_account"
        >Criar nova conta</b-button
      >
    </div>
  </div>
</template>

<script>
import schemas from '../schemas/AddEditFormSchema.js'
export default {
  props: ["bankList"],
  data() {
    return {
      editing: false,
      errors: [],
      model: {
        company_id: "",
        unity_id: this.$route.params.unityId,
        bank_id: "",
        description: "",
        account_type: "",
        agency: "",
        account: "",
        document: "",
        active: "1",
      },
      schemas: schemas({bankList:this.bankList})
    };
  },  
  methods: {
    async submit_new_account() {
      if (this.check_required() == 0) {
        const response = await this.$store.dispatch(
          "unities/save_bank_account",
          this.model
        );
        if (response) {
          this.$router.go(-1);
        }
      }
    },
    async update_account() {
      if (this.check_required() == 0) {
        const response = await this.$store.dispatch(
          "unities/update_bank_account",
          this.model
        );

        if (response) {
          this.$router.go(-1);
        }
      }
    },
    check_required() {
      this.errors = [];
      const flat = _.flatten(this.schemas);
      _.forEach(flat, (item) => {
        if (item.required) {
          if (!this.model[item.model]) {
            this.errors.push(item.model);
          }
        }
      });
      return this.errors.length;
    },
  },
  async mounted() {
    const accountId = this.$route.params.accountId;
    if (accountId) {
      const accountDetails = await this.$store.dispatch(
        "unities/get_bank_account_by_id",
        accountId
      );

      this.model = accountDetails;

      this.editing = true;
    }
  },
};
</script>

<style>
</style>